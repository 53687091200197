<template>
  <div class="c--language-dropdown">
    <div class="language-dropdown">
      <!-- <span class="fi fi-sy"></span> 
      <span class="fi fi-tr"></span> 
      <span class="fi fi-gb"></span>  -->
      <!-- {{ lang }}  -->
      <span class="btn-open-switch-lang" :class="getFlagIcon(lang, '')" @click="openSwitchLang"></span> 
      <div class="box-locale-changer">
        <select id="select-lang-public" v-model="$i18n.locale" @change="changeSwitchLang">
          <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
            <span class="fi fi-tr"></span>  
            {{ locale }}
          </option>
        </select>
      </div>
      
<!-- 
      <div class="box-locale-changer">
        <div v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :data-lang="locale" @click="changeSwitchLang">
          <span :class="getFlagIcon(locale, '')"></span>
          <span class="code-lang">{{ locale }}</span>
        </div>
      </div> -->
      
    </div>
  </div>
</template>

<script>
import 'flag-icons/css/flag-icons.css';
import { _changeSwitchLang } from '../../i18u';
// import { _getStartingLocale } from '../../i18u';

export default {
  name: 'LanguageDropdown',
  props: {
    msg: String
  },
  setup() {
    return { 
      lang: localStorage.getItem('MY_LANG_SOLTANCASH') || 'us',
    }
  },
  methods: {
    getFlagIcon(code, size) {
      return `fi fi-${code} ${size}`
    },
    openSwitchLang(){
      const highlightedItems = document.querySelectorAll(".box-locale-changer");
      highlightedItems.forEach((ele) => {
        if (ele.classList.contains("show")) { 
          ele.classList.remove("show");
        } else {
          ele.classList.add("show");
        }
      });

    },
    async changeSwitchLang(e) {
      const n_lang = e.target.value;
      _changeSwitchLang(n_lang)
    },
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c--footer {
  position: relative;
}
.language-dropdown {
  position: relative;
}

.btn-open-switch-lang {
  cursor: pointer;
  border-radius: 3px;
  transition: 0.4s ease;
}
.btn-open-switch-lang:hover {
  transform: scale(1.1);
  /* box-shadow: -8px -4px 13px rgba(var(--color-box-shadow-rgb), 0.2); */
  transition: 0.4s ease;
}
.box-locale-changer {
  background: var(--box-background);
  box-shadow: -8px -4px 13px rgba(var(--color-box-shadow-rgb), 0.2);
  padding: 15px;
  border-radius: 9px;
  position: absolute;
  top: 30px;
  min-width: 90px;
  width: 100%;
  z-index: 99999;
  display: none;
  opacity: 0;
  transform: scale(0.1);
  transition: 0.4s ease;
}

.box-locale-changer div {
  text-align: start;
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  transition: 0.4s ease;
}
.box-locale-changer div:hover {
  transform: scale(1.1);
  box-shadow: -8px -4px 13px rgba(var(--color-box-shadow-rgb), 0.2);
  transition: 0.4s ease;
}
.box-locale-changer.show {
  display: block;
  opacity: 1;
  transform: scale(1);
  transition: 0.4s ease;
}
.box-locale-changer div .code-lang {
  text-transform: uppercase;
  padding-inline-start: 9px;
  color: var(--color-text);
}
.box-locale-changer select {
  width: 100%;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0px !important;
  background-image: none;
  text-transform: uppercase;
}
.box-locale-changer select option {
  margin: 40px !important;
  padding: 40px !important;
  /* background: rgba(0, 0, 0, 0.3); */
  border: none !important;
  /* color: #fff; */
  /* text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}









.content-footer {
  position: relative;
  min-height: 65px;
  padding: 16px;
  background: var(--box-background);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.f-first {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.f-first .logo {
  width: 32px;
}
.f-first .info-app-footer{
  padding: 0px 12px;
  text-align: start;
}
.f-first .info-app-footer p {
  font-size: 12px;
  color: var(--color-text);
}
.f-first .info-app-footer h3 {
  font-size: 12px;
  color: var(--color-text);
}
.f-second .item {
  font-size: 12px;
  color: var(--color-text);
}
.f-second .item .btn-item {
  padding-inline-start: 5px;
  text-decoration: none;
  color: var(--public);
}
.f-third {
  text-align: start;
}
.f-third .item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 12px;
  direction: ltr;
}
.f-third .item .ic {
  color: var(--color-text);
}
.f-third .item span {
  padding: 0px 5px;
  color: var(--color-text);
}




@media (max-width: 992px) {
  .content-footer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .f-first,
  .f-second,
  .f-third {
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }
  .f-third {
    margin-bottom: unset;
    text-align: center;
  }
  .f-third .item {
    justify-content: center;
  }
}
</style>
